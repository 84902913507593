export class Company {
  // accountsLevel1: string;
  // accountsLevel2: string;
  // accountsLevel3: string;
  // accountsLevel4: string;
  // accountsLevel5: string;
  // accountsLevel6: string;
  // accountsLevel7: string;
  // accountsLevel8: string;
  // accountsLevel9: string;
  // accountsLevel10: string;
  // zipCode: string;
  // zipCodeDescription: string;
  // amazonStore: boolean;
  // auditLevel: string;
  // businessName: string;
  // controlProfitability: boolean;
  // createAt: string;
  // date: string;
  // defaultCurrency: string;
  // ean13: boolean;
  // earningAccounts: string;
  // edi: boolean;
  // email: string;
  // expirationLock: boolean;
  // handlePurchase: boolean;
  // id: string;
  // isCourier: boolean;
  // isFreight: boolean;
  // isWms: boolean;
  // localCurrency: string;
  // month: number;
  // multiCurrency: boolean;
  // orderLock: boolean;
  // password: string;
  // percentProfitability: number;
  // phoneNumber: string;
  // processIni: string;
  // profitEnding: string;
  // quoteLock: boolean;
  // serial: boolean;
  // sw: string;
  // tracking: boolean;
  // updateAt: string;
  // urlLogo: string;
  // urlPdf: string;
  // virtualLocker: boolean;
  // year: number;
  // zoneZc: boolean;
  // zonePricingLevel: string;
  // integrations: Integrations[] | null;
  // profile: string;

  id: string;
  identification: string;
  name: string;
  createdAt: Date;
  updatedAt: Date;
  updatedBy: string;
  address: string;
  territory: string;
  territoryId: string;
  defaultCurrency: string;
  email: string;
  phoneNumber: string;
  isCourier = false;
  isFreight = false;
  isWms = false;
  urlLogo: string;
  urlPdf: string;
  priorityPrice1 = 'customer';
  priorityPrice2 = 'zone';
  priorityPrice3 = 'supplier';
  zonePricingLevel = 'state';
  configProfile: string;
  zipCode: string;
  priorityPricing: string[];
  multiCurrency = false;

  public constructor() {
    this.id = '';
    this.identification = '';
    this.name = '';
    this.createdAt = new Date();
    this.updatedAt = new Date();
    this.updatedBy = '';
    this.address = '';
    this.territory = '';
    this.territoryId = '';
    this.defaultCurrency = '';
    this.email = '';
    this.phoneNumber = '';
    this.urlLogo = '';
    this.urlPdf = '';
    this.configProfile = '';
    this.zipCode = '';
    this.priorityPricing = ['customer', 'zone', 'supplier'];
  }
}
